.logo-text {
    color: #01aaad;
    font-weight: 700;
    font-family: "Nunito", sans-serif;
  }

  .logo {
    color: #e5eaea;
    font-weight: 500;
    font-family: "Nunito", sans-serif;
    text-decoration: none;
    font-size: 15px;
  }
  
  .demo-logo-vertical{
    margin:20px
  }
  
  .custom-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header-center {
    flex-grow: 1;
    text-align: center;
    color: #e5eaea;
    font-family: "Nunito", sans-serif;
  }
  
  .header-left,
  .header-right {
    margin: 0 24px;
    color: #e5eaea;
    font-family: "Nunito", sans-serif;
  }

  .header-buttons{
    margin-top: 16px;
  }
.header-buttons ul{
    display: flex;
    align-items: center;
    color: #e5eaea;
    font-family: "Nunito", sans-serif;
    margin-bottom: 0;
}
.header-buttons ul li{
    list-style: none;
    margin-left: 16px;
}
.header-buttons ul li button{
    border-radius: 5px;
    border:none;
    background: #01aaad ;
    color: #e5eaea;
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    padding: 5px 20px;
}
.header-buttons ul li button:hover{
    background: green;
    color: #e5eaea;
}
.logo{
  color:white;
  font-weight: 700;
  font-size: 20px;
}

.header-custom{
  background: #01aaad ;
  color: white;
}