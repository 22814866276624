.stats-table {
    width: 80%;
    margin: auto;
  }
  
  .stats-table .ant-table {
    border-collapse: collapse;
  }
  
  .stats-table .ant-table th.column-metric,
  /* .stats-table .ant-table td.column-metric, */
  .stats-table .ant-table th.column-count,
  .stats-table .ant-table th.column-sum {
    background-color: #38B2AC;
    color: white;
  }
  
  .stats-table .ant-table tr:hover td.column-metric,
  .stats-table .ant-table th.column-metric:hover,
  .stats-table .ant-table th.column-count:hover,
  .stats-table .ant-table th.column-sum:hover {
    background-color: #66D5D1; /* Lighter teal */
  }
  
  .stats-table .ant-table th,
  .stats-table .ant-table td {
    width: 33.33%;
  }  

  .pending-table .ant-table-container::before {
    border-radius: 0px;
  }
  .summary-cell {
    font-weight: bold;
  }
  