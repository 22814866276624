

.uneditable input, .uneditable select, .uneditable input[type="file"] {
        /* Set the background color to gray */
        background-color: #E2E8F0;
        /* Disable pointer events */
        pointer-events: none;
}

.uneditable textarea {
        /* Set the background color to gray */
        background-color: #E2E8F0;

}